import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { getFormData } from '../../redux/slice/formData';
import { getGlobalContentOverrides, getIsWideLogo, isWhiteLabelMode, showInstallerLogo } from '../../redux/slice/ui';
import { DEFAULT_IQ_HEADER_TEXT } from '../../utils/constants';
import ContactTelephoneNumber from './ContactTelephoneNumber';
import LogoHeaderItem from './LogoHeaderItem';

type InstantQuoteHeaderOwnProps = {
  className?: string;
  showResidence?: boolean;
};

function mapStateToProps(state: RootState) {
  const { lastName, streetAddress, city, state: stateCode, zipCode } = getFormData(state);
  const addrLine2 = stateCode ? `${city}, ${stateCode} ${zipCode}` : `Zip ${zipCode}`;
  const contentOverrides = getGlobalContentOverrides(state);
  const isWideLogo = getIsWideLogo(state);
  const iqHeaderText = contentOverrides.iqHeaderText || DEFAULT_IQ_HEADER_TEXT;

  return {
    isWhiteLabelMode: isWhiteLabelMode(state),
    showLogo: showInstallerLogo(state),
    lastName,
    streetAddress,
    addrLine2,
    isWideLogo,
    iqHeaderText,
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type InstantQuoteHeaderProps = PropsFromRedux & InstantQuoteHeaderOwnProps;

class InstantQuoteHeader extends React.PureComponent<InstantQuoteHeaderProps> {
  render() {
    const {
      className,
      isWhiteLabelMode,
      lastName,
      streetAddress,
      addrLine2,
      showResidence,
      showLogo,
      iqHeaderText,
      isWideLogo,
    } = this.props;

    return (
      <div
        className={classNames(
          'InstantQuoteHeader pb-4 d-flex justify-content-md-center align-items-center align-items-md-end flex-wrap',
          className,
          { 'justify-content-between': !isWideLogo, 'justify-content-end justify-content-sm-between': isWideLogo },
        )}
      >
        {showLogo && (
          <LogoHeaderItem small className={classNames('d-md-none', { 'col-12 col-sm-6 mb-2': isWideLogo })} />
        )}
        <div className="d-flex flex-column contractor-info">
          {isWhiteLabelMode && <ContactTelephoneNumber className="d-md-none text-muted text-end" />}
          {lastName && streetAddress && addrLine2 && showResidence ? (
            <div className="fs-5 text-end text-md-center">
              <div>{lastName} Residence</div>
              <div>{streetAddress}</div>
              <div>{addrLine2}</div>
            </div>
          ) : (
            <div className="d-none d-md-block fs-5 text-end text-md-center">{iqHeaderText}</div>
          )}
        </div>
      </div>
    );
  }
}

export default connector(InstantQuoteHeader);
