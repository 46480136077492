import type { ReactEventHandler } from 'react';
import Image, { type ObjectFit } from './Image';

type InstallerLogoImageProps = {
  src: string;
  size: { width: number; height: number } | { objectFit: ObjectFit };
  name?: string;
  partnerSlug?: string;
  className?: string;
onLoad?: ReactEventHandler<HTMLImageElement>;
};

export default function InstallerLogoImage(props: Readonly<InstallerLogoImageProps>) {
  const { src, name, partnerSlug, size, onLoad, ...rest } = props;
  const altText = name || partnerSlug || ' logo';
  return (
    <Image
      src={src}
      alt={altText}
      {...size}
      fill={Object.hasOwn(size, 'objectFit')}
      sizes={Object.hasOwn(size, 'objectFit') ? '250px' : undefined}
      onLoad={onLoad}
      {...rest}
    />
  );
}
