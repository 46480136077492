import autoBind from 'auto-bind';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import LogoSVG from '../../public/images/e-den_full.svg';
import type { RootState } from '../../redux/slice';
import { getInstallerLogo, getPartnerSettings, getUiStore, showInstallerLogo } from '../../redux/slice/ui';
import { BASE_E_DEN_URL, EDEN_PARTNER_SLUG } from '../../utils/constants';
import type { ObjectFit } from './Image';
import InstallerLogoImage from './InstallerLogoImage';

function mapStateToProps(state: RootState) {
  const ui = getUiStore(state);
  const { partnerSlug, partnerDataLoaded } = ui;
  const partnerSettings = getPartnerSettings(state);
  const { homePageUrl, name, logoWidth, logoHeight } = partnerSettings;
  const showLogo = showInstallerLogo(state);
  const installerLogo = getInstallerLogo(state);
  return {
    partnerSlug,
    partnerDataLoaded,
    showLogo,
    logoUrl: installerLogo,
    logoWidth,
    logoHeight,
    homePageUrl: homePageUrl || BASE_E_DEN_URL,
    name,
  };
}

type LogoHeaderItemOwnProps = {
  small?: boolean;
  className?: string;
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type LogoHeaderItemProps = PropsFromRedux & LogoHeaderItemOwnProps;

class LogoHeaderItem extends React.PureComponent<LogoHeaderItemProps> {
  constructor(props: LogoHeaderItemProps) {
    super(props);
    autoBind(this);
  }

  render() {
    const { homePageUrl, small, className } = this.props;
    return (
      <div className={classNames('LogoHeaderItem', { small }, className)}>
        <a href={homePageUrl} data-animation-role="header-element">
          {this.displayLogo()}
        </a>
      </div>
    );
  }

  displayLogo(): React.ReactNode {
    const { partnerDataLoaded, partnerSlug, showLogo, logoUrl, logoWidth, logoHeight, name } = this.props;
    if (!partnerDataLoaded) return null;

    if (partnerSlug === EDEN_PARTNER_SLUG) {
      return <LogoSVG></LogoSVG>;
    }

    if (!showLogo || !logoUrl) return null;

    const size: { width: number; height: number } | { objectFit: ObjectFit } =
      logoWidth && logoHeight ? { width: logoWidth, height: logoHeight } : { objectFit: 'contain' };

    return <InstallerLogoImage src={logoUrl} name={name} partnerSlug={partnerSlug} size={size} />;
  }
}

export default connector(LogoHeaderItem);
