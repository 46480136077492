import React from 'react';
import FormUtil from '../../utils/formUtil';
import ContactorLicense from './ContractorLicense';
import PoweredByEDEN from './PoweredByEDEN';

type StepPanelProps = {
  header: string;
  subHeader?: string;
  children: React.ReactNode;
  infoBox?: React.ReactElement;
  headerRight?: React.ReactElement;
  className?: string;
};

export default function StepPanel(props: Readonly<StepPanelProps>) {
  const { header, children, headerRight, infoBox, subHeader } = props;
  const headerBottomMargin = subHeader ? 'mb-2' : 'mb-4';

  return (
    <>
      <form className="form-area needs-validation" noValidate onSubmit={FormUtil.ignoreFormSubmit}>
        {infoBox}
        <div className={`d-flex align-items-center justify-content-between ${headerBottomMargin}`}>
          {header && <h2 className="StepHeader fw-bold mb-0">{header}</h2>}
          {headerRight}
        </div>
        {subHeader && <div className="mb-4 pb-1">{subHeader}</div>}
        {children}
      </form>
      <ContactorLicense className="d-md-none text-muted mt-3" />
      <PoweredByEDEN className="mt-md-3 mb-5" />
    </>
  );
}
